import styled, { device, themeGet } from "@styled";

export const FormWrapper = styled.section`
  .hs-form-iframe 
    height:auto !important;
  }
`;
export const PrivacyPolicyTag = styled.p`
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 1.2;
  color: #ffffff;
  margin-top:5px;
`
export const ContactFormWrap = styled.div`
  
`;
