import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Heading from "@ui/heading";
import Button from "@ui/button";
// import { Link } from "gatsby";
import Image from "@ui/image";

import {
  MachineTranslationWrapper,
  MachineTranslationInner,
  MachineTranslationBlock,
  MachineTranslationBlockInner,
  ButtonWrap,
} from "./style";

const MachineTranslation = React.memo(({
  HeadingStyle,
  data,
}) => {
  return (
    <MachineTranslationWrapper id={data.section}>
      <MachineTranslationInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
              />
            </Col>
          </Row>

          <Row>
            {data &&
              data.items.map((el, index) => (
                <Col lg={6} key={index} className="pt-lg-0 pt-4">
                  <MachineTranslationBlock>
                    <MachineTranslationBlockInner>
                      {/* <div className="mac-trs-iconwrap">
                        <Image
                          src={el.images[0].src}
                          alt={el.images[0]?.alt || "Icon"}
                        />
                      </div> */}
                      <div className="mac-trs-textwrap">
                        <Heading {...HeadingStyle}>{el.title}</Heading>
                        <Text>{el.description}</Text>
                      </div>
                    </MachineTranslationBlockInner>
                  </MachineTranslationBlock>
                </Col>
              ))}
          </Row>
          <Row className="mt-lg-5 mt-lg-md mt-3">
            <Col>
              <ButtonWrap className="text-center">
                {data?.images.slice(0, 1)?.map(({ id, src,alt, ...rest }) => (
                  // <Button key={id} m="7px" {...rest}>
                  //   {content}
                  // </Button>
                  <Image
                  src={src}
                  alt={alt || "image"}
                />
                ))}
       
              </ButtonWrap>
            </Col>
          </Row>
        </Container>
      </MachineTranslationInner>
    </MachineTranslationWrapper>
  );
})
MachineTranslation.propTypes = {
  SectionTitleStyle: PropTypes.object,
  HeadingStyle: PropTypes.object,
};
MachineTranslation.defaultProps = {
  SectionTitleStyle: {
  },
  HeadingStyle: {
    as: "h6",
  },
};
export default MachineTranslation;