import styled, { device, themeGet } from "@styled";
import waveBg from "@data/images/bg/wave-bg.svg";
import heroBg from "@data/images/hero/home-banner-img.jpg";
import { animateUpDown } from "@assets/css/animations";

export const HeroWrapper = styled.section`
  padding: 7.625rem 0;
  position: relative;
  display: flex; 
  align-items: center;
  overflow: hidden;
  // background: url(${heroBg}) center/cover no-repeat transparent;

  ${device.large} {
    min-height: 750px;
  }
  ${device.xlarge} {
    min-height: 825px;
    padding-top: 150px;
  }
  ${device.xxlarge} {
    min-height: 1100px;
    padding-top: 200px;
    padding-bottom: 120px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 103px;
    background: url(${waveBg}) center/cover no-repeat;
    z-index: 9;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      320deg,
      ${themeGet("colors.secondaryDark")},
      ${themeGet("colors.secondary")}
    );
    z-index: -1;
  }

  .container {
    ${device.xxlarge} {
      max-width: 1570px;
    }
  }
`;

export const HeroWrapperText = styled.div`
  z-index: 4;
  position: relative;
`;
export const HeroWrapperTextTop = styled.div`
  h3 {
    color: #fa4f19;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;

    ${device.xsmall} {
      font-size: 1rem;
    }

    ${device.small} {
      font-size: 20px;
    }
  }

  h1 {
    font-size: 36px;
    color: #fff;
    font-weight: bold;
    line-height: 1;
    border-left: 5px solid #f64f1a;
    padding-left: 10px;
    margin: 15px 0 20px;

    span {
      color: #f6511e;
      transform: skew(-15deg, 0deg);
      display: inline-block;
    }

    ${device.xsmall} {
      font-size: 1.5rem;
      padding-left: 0.5rem;
      margin: 0.7rem 0;
      border-left: 2px solid rgb(246, 79, 26);
    }
    ${device.small} {
      font-size: 50px;
    }

    ${device.xlarge} {
      font-size: 60px;
      padding-left: 20px;
    }

    ${device.xxlarge} {
      font-size: 100px;
    }
  }

  h2 {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;

    ${device.xsmall} {
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }

    ${device.small} {
      font-size: 20px;
    }

    ${device.xlarge} {
      margin-bottom: 40px;
    }
  }

  a {
    &:not(:last-child) {
      margin-left: 0;
    }
  }
`;

export const HeroWrapperTextBottom = styled.div`
  margin-top: 40px;

  ${device.large},
  ${device.xlarge},
  ${device.xxlarge} {
    margin-top: 40px;
  }

  p {
    font-size: 20px;
    color: #fff;
    line-height: 1.2;

    span {
      font-size: 15px;
      color: #dbb5f6;
    }

    ${device.xsmall} {
      font-size: 1rem;
      line-height: 1.4;

      span {
        font-size: 0.7rem;
      }
    }
  }

  ${device.xsmall} {
    margin-top: 1.5rem;

    h5 {
      font-size: 1rem;
    }
  }
`;

export const HerologosList = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${device.medium} {
    margin-top: 30px;
    flex-wrap: nowrap;
  }

  ${device.xsmall} {
    margin-top: 1.875rem;
  }
`;

export const HerologosItem = styled.div`
  padding: 10px;
  flex: 1 1 33.33%;
  display: flex;
  align-items: center;

  ${device.medium} {
    &:not(:last-child) {
      margin-right: 30px;
      padding: 0;
      flex: auto;
    }
  }

  ${device.xsmall} {
    padding: 0.3rem;
    flex: 16.33%;
  }
`;

export const HeroFloatingImgs = styled.div`
  position: relative;
  top: 30px;
  max-width: 350px;
  margin: 30px auto 0;

  ${device.small} {
    max-width: initial;
  }

  ${device.large} {
    margin-top: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 0;
    max-width: 390px;
  }

  ${device.xlarge} {
    max-width: 480px;
  }

  ${device.xxlarge} {
    max-width: 650px;
  }
`;

export const PreviewInnerImg = styled.div`
  ${device.large} {
    max-width: 490px;
    margin: auto;
    position: absolute;
    bottom: 60px;
    display: inline-block;
    width: 100%;
    left: 0;
    right: 0;
  }

  ${device.xlarge} {
    max-width: initial;
  }
`;

export const FloatingImage = styled.div`
  &.pr-img {
    &-01,
    &-02,
    &-03,
    &-04,
    &-05,
    &-06,
    &-07 {
      position: absolute !important;
      text-align: right;

      img {
        width: 70%;
        margin-left: auto;

        ${device.small} {
          width: 80%;
        }

        ${device.large} {
          width: 70%;
        }

        ${device.xlarge} {
          width: auto;
        }
      }

      &-01 {
        top: 36%;
        right: 7%;

        ${device.medium} {
          top: 40%;
          right: 14%;
        }

        ${device.large} {
          top: 57%;
          right: 3%;
        }

        ${device.xlarge} {
          top: 58%;
          right: 10%;
        }
      }

      &-02 {
        top: 28%;
        right: 0;

        ${device.large} {
          top: 52%;
        }
      }

      &-03 {
        left: 15%;
        top: 4%;
        text-align: left;

        ${device.medium} {
          top: 4%;
          left: 20%;
        }

        ${device.large} {
          top: 40%;
          left: 16%;
        }

        ${device.xlarge} {
          top: 36%;
          left: 20%;
        }
      }

      &-04 {
        bottom: 10%;
        left: 0;
        text-align: left;

        ${device.medium} {
          bottom: 9%;
          left: 7%;
        }

        ${device.large} {
          bottom: 16%;
          left: 0;
        }

        ${device.xlarge} {
          bottom: 14%;
          left: 7%;
        }
      }

      &-05 {
        left: 25%;
        bottom: 22%;
        text-align: left;

        ${device.medium} {
          bottom: 22%;
          left: 28%;
        }

        ${device.large} {
          bottom: 20%;
          left: 25%;
        }

        ${device.xlarge} {
          bottom: 22%;
          left: 28%;
        }
      }

      &-06 {
        bottom: 4%;
        right: 6%;

        ${device.medium} {
          bottom: 6%;
          right: 17%;
        }

        ${device.large} {
          bottom: 13%;
          right: 0;
        }

        ${device.xlarge} {
          bottom: 10%;
          right: 13%;
        }
      }

      &-07 {
        bottom: 28%;
        right: 0;

        ${device.medium} {
          bottom: 28%;
          right: 8%;
        }

        ${device.large} {
          bottom: 27%;
          right: 0;
        }

        ${device.xlarge} {
          bottom: 24%;
          right: 2%;
        }
      }
    }

    &.animation_images {
      animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
      animation-name: ${animateUpDown};
      animation-iteration-count: infinite;

      &.one {
        animation-duration: 5.2s;
      }

      &.two {
        animation-duration: 5.8s;
      }

      &.three {
        animation-duration: 5.8s;
      }
    }
  }
`;
export const StyledBG = styled.div`
  inset: 0;
  z-index: -1;
  margin-top: 60px;
 cursor: pointer;
  ${device.large} {
    margin-top: 0;
  }
  & > div {
    height: 100%;
    width: 100%;
  }
  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }
`;
// export const StyledBG = styled.div`
//   inset: 0;
//   z-index: -1;
//   margin-top: 60px;
//   ${device.large} {
//     margin-top: 0;
//   }
//   & > div {
//     height: 100%;
//     width: 100%;
//   }
//   .gatsby-iframe {
//    max-width: 500px;
//      max-width: 500px;
//   margin: 0 auto; 
//   }
//   iframe {
//   border: solid 1px #EAEAEA;
//   width: 100%;
//   min-height: 500px;  
//   overflow: hidden;
// }

// a {
//   font-size: 12px;
//   color: #dbb5f6;
//   font-weight: 600;
// }
// ${device.medium} {
//      iframe {
//     min-height: 410px;  
//   }  
// }
//   @media (max-width: 767px) {
//   .gatsby-iframe {
//     max-width: 100%; 
//   }

//   iframe {
//     min-height: 300px;  
//   }
// }
       
// `;
export const HeroCenterIcon = styled.div`
  position: absolute;
  top: calc(100% + 20px);
  left:50%;
  transform:translate(-50%, 0);
  width: fit-content;
  animation:heroAnimate 1.5s infinite linear alternate;
  cursor:pointer;
  ${device.xxxlarge} {
    top: calc(100% + 65px);
  }
  @keyframes heroAnimate{
    0%{
      transform:translate(-50%, 0);
    }
    100%{
      transform:translate(-50%, -10px);
    }
  }
  img {
    max-width: 25px !important;
    ${device.xxxlarge} {
      max-width: 100% !important;
    }
  }
}
`;