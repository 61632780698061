import React, { useEffect } from "react";
import { FormWrapper, ContactFormWrap, PrivacyPolicyTag } from "./style";
import HeroQuoteForm from "./HeroQuoteForm";
export default function HeroQuote() {
  useEffect(() => {
    HeroQuoteForm();
  }, []);

  return (
    <>
      <FormWrapper className="contact-form-wrap">
        <ContactFormWrap id="heroQuote"></ContactFormWrap>
        <PrivacyPolicyTag>
          By Submitting this form, you are agreeing to Andovar’s Privacy Policy
        </PrivacyPolicyTag>
      </FormWrapper>
    </>
  );
}
