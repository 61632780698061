import styled, { css, device } from "@styled";

export const CompanyreviewWrapper = styled.section`
  padding: 90px 0;

  ${(props) =>
    props.layout === 1 &&
    css`
      background: #f8f8f8;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      padding: 40px 0 80px;
      .section-title {
        display: none;
      }
    `}
`;
export const CompanyreviewInner = styled.div``;
export const CompanyContant = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  // margin-top: 40px;
  ${device.small} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.large} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
  ${device.xlarge} {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
  }

  .far.fa-star, .fa.fa-star {
    font-size: 22px;
  }

  .far.fa-star {
    color: #888;
  }

  .google {
    p {
      color: #3b7ded;
    }
    .fa.fa-star {
      color: #3b7ded;
    }
  }

  .proz {
    p {
      color: #35605d;
    }
    .fa.fa-star {
      color: #35605d;
    }
  }

  .nps {
    p {
      color: #00a651;
    }
    .fa.fa-star {
      color: #00a651;
    }
  }

  .g2 {
    p {
      color: #ff2f09;
    }
    .fa.fa-star {
      color: #ff2f09;
    }
  }

`;
export const CompanyContantInner = styled.div`
  background: rgb(255 255 255 / 40%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  position: relative;
  ${device.xlarge} {
    padding: 15px;
  }
  ${device.xxlarge} {
    padding: 20px;
  }
  h4 {
    font-weight: bold;
    font-size: 16px;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 30px;
    font-weight: bold;
  }

  .ratings .fa-star.disable {
    color: #aaa;
  }

  .ratings svg {
    width: 22px;
    vertical-align: text-bottom;
    display: inline-block;
    margin-left: 5px;
    fill: #ffb944;
    margin-bottom: 1.2px;
  }
`;
export const CompanyLogo = styled.div`
  width: 130px;
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${device.medium} {
    height: 50px;
  }
  ${device.xxlarge} {
    height: 60px;
  }
  img, .gatsby-image-wrapper {
    height: 100%;
    object-fit: contain;
  }
  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }
`;
